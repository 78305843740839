body {
    padding-top: 60px;
    padding-bottom: 40px;
}

.part-autocomplete-input {
    min-width: 110px;
}

input:disabled::-webkit-outer-spin-button,
input:read-only::-webkit-outer-spin-button,
input:disabled::-webkit-inner-spin-button,
input:read-only::-webkit-inner-spin-button,
input[type=number]:disabled,
input[type=number]:read-only {
    margin: 0;
    appearance: none;
    -moz-appearance: textfield;
}

#remove-modal {
    width: 400px;
    margin-left: 110px;
    margin-top: 100px;
}

.navbar-inverse {
    background: #0085c3;
    border-color: white;
}

.navbar-inverse .navbar-nav > .active > a {
    background: none;
}

.navbar-inverse .navbar-nav > .active > a:hover {
    background: none;
}

.navbar-inverse .navbar-nav > li > a {
    color: white;
    font-weight: bold;
}

#changeDate {
    font-size: 20px;
    font-weight: bold;
    color: green;
    margin-top: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.pagination {
    height: 36px;
    margin: 18px 0;
}

.pagination ul {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */
    margin-left: 0;
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination li {
    display: inline;
}

.pagination a {
    float: left;
    padding: 0 14px;
    line-height: 34px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-left-width: 0;
    font-weight: bold;
}

.pagination a:hover,
.pagination .active a {
    background-color: #f5f5f5;
}

.pagination .active a {
    color: black;
    cursor: default;
}

.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
    color: black;
    background-color: transparent;
    cursor: default;
}

.pagination li:first-child a {
    border-left-width: 1px;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

.pagination-centered {
    text-align: center;
}

.pagination-right {
    text-align: right;
}

.pager {
    margin-left: 0;
    margin-bottom: 18px;
    list-style: none;
    text-align: center;
}

.pager:before,
.pager:after {
    display: table;
    content: "";
}

.pager:after {
    clear: both;
}

.pager li {
    display: inline;
}

.pager a {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

.pager a:hover {
    text-decoration: none;
    background-color: #f5f5f5;
}

.pager .next a {
    float: right;
}

.pager .previous a {
    float: left;
}

.pager .disabled a,
.pager .disabled a:hover {
    color: #999999;
    background-color: #fff;
    cursor: default;
}

hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    border-top: 5px solid #868686;
}

.modalStocks .modal-dialog {
    width: 1050px; /* your width */
}

.modalStocks .modal-dialog .modal-body {
    margin-left: -10px;
}

.none {
    display: none;
}

dl.text-left > dt, dl.text-left > dt {
    text-align: left;
}

/* bootstrap chosen theme start */
select.form-control + .chosen-container.chosen-container-single .chosen-single {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    background-image: none;
}

select.form-control + .chosen-container.chosen-container-single .chosen-single div {
    top: 4px;
    color: #000;
}

select.form-control + .chosen-container .chosen-drop {
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    margin: 2px 0 0;

}

select.form-control + .chosen-container .chosen-search input[type=text] {
    display: block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #FFF;
    border: 1px solid #CCC;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-image: none;
}

select.form-control + .chosen-container .chosen-results {
    padding: 5px 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    margin: 2px 0 5px;
}

select.form-control + .chosen-container .chosen-results li,
select.form-control + .chosen-container .chosen-results li.active-result {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333;
    white-space: nowrap;
    background-image: none;
}

select.form-control + .chosen-container .chosen-results li:hover,
select.form-control + .chosen-container .chosen-results li.active-result:hover,
select.form-control + .chosen-container .chosen-results li.highlighted {
    color: #FFF;
    text-decoration: none;
    background-color: #428BCA;
    background-image: none;
}

select.form-control + .chosen-container-multi .chosen-choices {
    display: block;
    width: 100%;
    min-height: 34px;
    padding: 6px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    vertical-align: middle;
    background-color: #FFF;
    border: 1px solid #CCC;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-image: none;
}

select.form-control + .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    height: auto;
    padding: 5px 0;
}

select.form-control + .chosen-container-multi .chosen-choices li.search-choice {

    background-image: none;
    padding: 3px 24px 3px 5px;
    margin: 0 6px 0 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    background-color: #FFF;
}

select.form-control + .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: 8px;
    right: 6px;
}

.chosen-container-single .chosen-single abbr {
    top: 12px;
}

select.form-control + .chosen-container-multi.chosen-container-active .chosen-choices,
select.form-control + .chosen-container.chosen-container-single.chosen-container-active .chosen-single,
select.form-control + .chosen-container .chosen-search input[type=text]:focus {
    border-color: #66AFE9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

select.form-control + .chosen-container-multi .chosen-results li.result-selected {
    display: list-item;
    color: #ccc;
    cursor: default;
    background-color: white;
}

/**
 * bootstrap chosen theme end
 */

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
